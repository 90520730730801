import classNames from "classnames";
import React, { FC } from "react";

type SecondaryGridProps = {
  left: React.ReactElement;
  right: React.ReactElement;
};

const SecondaryGrid: FC<SecondaryGridProps> = (props) => {
  return (
    <div className={classNames("flex", "md:gap-5", "w-full", "mt-5")}>
      <section className={classNames("flex-[2]")}>{props.left}</section>
      <section
        className={classNames("flex-1", "hidden", "md:block", "min-w-[300px]")}
      >
        {props.right}
      </section>
    </div>
  );
};

export default SecondaryGrid;
