import classNames from "classnames";
import { FC } from "react";

type PlaceholderProps = {
  width: string | number;
  height: string | number;
};

const Placeholder: FC<PlaceholderProps> = (props) => {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        minWidth: props.width,
        minHeight: props.height,
      }}
      className={classNames("bg-gray-200")}
    />
  );
};

export default Placeholder;
