import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";
import { getLeagueLogoUrl } from "../../data/leagues";
import { API } from "../../services/api/types";
import { humanizeDate } from "../../utils/date";
import slugify from "../../utils/slugify";
import Placeholder from "../Placeholder";
import ArticleFooter from "./ArticleFooter";
import styles from "./styles.module.css";
import Image from 'next/image';
import { createImageSizes } from '../../utils/image';

type ArticleItemLargeProps = {
  article: API.Article;
  ratio?: "1:1" | "16:9";
  hideImage?: boolean;
};

const ArticleItemSmall: FC<ArticleItemLargeProps> = (props) => {
  const isSquare = props.ratio === "16:9" ? false : true;
  const leagueLogoUrl = props.article.leagueLogo
    ? `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}${props.article.leagueLogo}`
    : getLeagueLogoUrl(props.article.categoriesId) || null;

  return (
    <Link
      href={`/nyheter/${slugify(props.article.title)}/${props.article.id}`}
      passHref
    >
      <a>
        <article
          className={classNames([
            styles.article,
            "cursor-pointer",
            "flex",
            "gap-5",
          ])}
        >
          {!props.hideImage &&
            (props.article.imageLarge ? (
              <div className={classNames(
                {
                  "max-w-[80px]": isSquare,
                  "max-w-[142px]": !isSquare,
                },
                ["relative", "flex-1", "h-[80px]"]
              )}>
                <Image
                  layout='fill'
                  sizes={createImageSizes({
                    mobile: '15vw',
                    tablet: '15vw',
                    desktop: '15vw'
                  })}
                  className='object-cover'
                  src={`${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}${props.article.imageLarge}`}
                  alt="Artikelbild" // TODO: Get image description from DB
                />
              </div>
            ) : (
              <Placeholder width={isSquare ? 80 : 142} height={80} />
            ))}
          <div className={classNames(["flex", "flex-col", "flex-1"])}>
            <h2
              className={classNames([
                "text-base",
                "font-bold",
                "text-text-primary",
                "line-clamp-2",
                "mb-2",
                "pb-[0.04em]",
              ])}
            >
              {props.article.titleShort || props.article.title}
            </h2>

            <div className={classNames(["mt-auto"])}>
              <ArticleFooter
                leaugeIcon={leagueLogoUrl}
                leagueName={props.article.categoriesName}
                date={humanizeDate(props.article.date, {
                  later: { excludeTime: true },
                })}
              />
            </div>
          </div>
        </article>
      </a>
    </Link>
  );
};

export default ArticleItemSmall;
