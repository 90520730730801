import clientApi from './clientApi';
import { API } from "./types";
import { fetchResource, updateResource } from "./utils";

export const getArticle = (id: number) => () => {
  return fetchResource<API.Article>(`/news/list/${id}`);
};

export const getArticleTags = (id: number) => () => {
  let route = `/news/${id}/tags`;
  return fetchResource<API.ArticleTag[]>(route);
};

// TODO: Refactor this. Too redundant
export const getMostViewedNews = (options?: {
  limit?: number;
  after?: string;
}) => {
  let route = `/news/views`;
  const query = new URLSearchParams();
  if (options?.limit) query.append("limit", String(options.limit));
  if (options?.after) query.append("after", options.after);
  if (query.toString()) route = `${route}?${query}`;
  return fetchResource<API.Article[]>(route);
};

export const getNewsStart = (options?: {
  limit?: number;
  after?: string;
  offset?: number;
}) => {
  let route = `/news/start`;
  const query = new URLSearchParams();
  if (options?.limit) query.append("limit", String(options.limit));
  if (options?.offset) query.append("offset", String(options.offset));
  if (options?.after) query.append("after", options.after);
  if (query.toString()) route = `${route}?${query}`;
  return fetchResource<API.Article[]>(route);
};

export const getNewsOne = () => {
  return fetchResource<API.Article>(`/news/one`);
};

export const getNewsList = (options?: {
  limit?: number;
  after?: string;
  category?: number;
  offset?: number;
}) => {
  let route = `/news/list`;
  const query = new URLSearchParams();
  if (options?.limit) query.append("limit", String(options.limit));
  if (options?.offset) query.append("offset", String(options.offset));
  if (options?.after) query.append("after", options.after);
  if (options?.category) query.append("category", String(options.category));
  if (query.toString()) route = `${route}?${query}`;
  return fetchResource<API.Article[]>(route);
};

export const getAllNews = () => {
  return fetchResource<API.Article[]>("/news");
};

export const incrementViewCount = (id: number) => {
  return updateResource(`/news/${id}/viewed`);
};

export const getPersonalNewsFeed = (options?: {
  limit?: number;
  offset?: number;
}) => {
  let route = `/profiles/feed`;

  const query = new URLSearchParams();
  if (options?.limit) query.append("limit", String(options.limit));
  if (options?.offset) query.append("offset", String(options.offset));
  if (query.toString()) route = `${route}?${query}`;

  return clientApi.get<API.Article[]>(route);
};
