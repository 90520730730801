import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";
import { getLeagueLogoUrl } from "../../data/leagues";
import { API } from "../../services/api/types";
import { humanizeDate } from "../../utils/date";
import slugify from "../../utils/slugify";
import Placeholder from "../Placeholder";
import ArticleFooter from "./ArticleFooter";
import styles from "./styles.module.css";
import Image from 'next/image';
import { createImageSizes } from '../../utils/image';

type ArticleItemLargeProps = {
  article: API.Article;
};

const ArticleItemLarge: FC<ArticleItemLargeProps> = (props) => {
  const leagueLogoUrl = props.article.leagueLogo
    ? `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}${props.article.leagueLogo}`
    : getLeagueLogoUrl(props.article.categoriesId) || null;

  return (
    <Link
      href={`/nyheter/${slugify(props.article.title)}/${props.article.id}`}
      passHref
    >
      <a>
        <article className={classNames([styles.article, "cursor-pointer"])}>
          {props.article.imageLarge ? (
            <div className={classNames("relative", styles["image"])}>
              <Image
                layout='fill'
                sizes={createImageSizes({
                  mobile: '100vw',
                  tablet: '50vw',
                  desktop: '75vw'
                })}
                className="object-cover"
                src={`${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}${props.article.imageLarge}`}
                alt="Artikelbild" // TODO: Get image description from DB
              />
            </div>
          ) : (
            <Placeholder width={"100%"} height={380} />
          )}

          <h2
            className={classNames([
              "mt-5",
              "text-3xl",
              "md:text-4xl",
              "font-bold",
              "text-text-primary",
              "line-clamp-2",
              "pb-[0.08em]",
            ])}
          >
            {props.article.titleShort || props.article.title}
          </h2>

          <p
            className={classNames([
              "mt-2",
              "text-text-primary",
              "mb-5",
              "line-clamp-3",
            ])}
          >
            {props.article.excerpt}
          </p>

          <ArticleFooter
            leaugeIcon={leagueLogoUrl}
            leagueName={props.article.categoriesName}
            date={humanizeDate(props.article.date, {
              later: { excludeTime: true },
            })}
          />
        </article>
      </a>
    </Link>
  );
};

export default ArticleItemLarge;
