import classNames from "classnames";
import { FC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import AdsIds from "../../../../data/ads";
import { getLeagueMenu } from "../../../../data/leagues";
import { API } from "../../../../services/api/types";
import { randomNumber } from "../../../../utils/number";
import Advertisement from "../../../Advertisement";
import ArticleItem from "../../../ArticleItem";
import BreakingTeaser from "../../../BreakingTeaser";
import { BreakingTeaser as BreakingTeaserType } from "../../../BreakingTeaser/types";
import SectionHeader from "../../../SectionHeader";
import SectionMenuButton from "../../../SectionMenu/SectionMenuButton";

type MainFeedDesktopProps = {
  news: API.Article[];
  breakingTeasers: BreakingTeaserType[];
  category: FixMeLater;
  onReachEnd: () => void;
  onClickCategory: (category: FixMeLater, index: number) => void;
  oneArticle?: API.Article;
};

const MainFeedDesktop: FC<MainFeedDesktopProps> = (props) => {
  return (
    <InfiniteScroll hasMore={true} pageStart={0} loadMore={props.onReachEnd}>
      {props.breakingTeasers.map((breakingTeaser, index) => {
        const isLast = index === props.breakingTeasers.length - 1;

        return (
          <div
            key={breakingTeaser.link}
            className={classNames({ "border-b": !isLast }, "py-2")}
          >
            <BreakingTeaser teaser={breakingTeaser} />
          </div>
        );
      })}
      <div className={classNames("mb-3")} />
      <SectionHeader
        label="Kategori:"
        menu={
          <SectionMenuButton
            activeItem={props.category.index}
            items={getLeagueMenu().map((league, index) => {
              return {
                label: league.title,
                onClick: () => props.onClickCategory(league, index),
              };
            })}
          />
        }
      />
      {props.oneArticle && (
        <div className={classNames(["py-5", "border-b"])}>
        <ArticleItem article={props.oneArticle} type="large" />
      </div>
      )}
      {[
        ...props.news.slice(0, 3),
        "vi-ad",
        ...props.news.slice(3, Infinity),
      ].map((article, index) => {
        const isShowingAd = index % 6 === 0 && index !== 0;
        const isShowingNativeAd = index === 2;
        const type = index % 3 === 0 ? "large" : "medium";

        if (typeof article === "string") {
          return (
            <div key={"vi-ad"} className={classNames(["py-5", "border-b"])}>
              <Advertisement id={AdsIds.desktop_vi} />
            </div>
          );
        }

        return (
          <div key={article.id}>
            {isShowingAd && (
              <div className={classNames(["py-5", "border-b"])}>
                <Advertisement
                  id={AdsIds.dynamic_desktop}
                  codeReplaceAll={() => ({
                    tagId: `fotbolltransfers_mpu_${randomNumber(
                      1,
                      Math.pow(10, 6)
                    )}`,
                  })}
                />
              </div>
            )}
            <div className={classNames(["py-5", "border-b"])}>
              <ArticleItem article={article} type={type} />
            </div>
            {isShowingNativeAd && (
              <div className={classNames(["py-5", "border-b"])}>
                <Advertisement id={AdsIds.desktop_native} />
              </div>
            )}
          </div>
        );
      })}
    </InfiniteScroll>
  );
};

export default MainFeedDesktop;
