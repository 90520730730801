import React, { FC } from "react";
import { API } from "../../services/api/types";
import ArticleItemLarge from "./ArticleItemLarge";
import ArticleItemMedium from "./ArticleItemMedium";
import ArticleItemSmall from "./ArticleItemSmall";

type ArticleItemProps = {
  type: "small" | "medium" | "large";
  article: API.Article;
  ratio?: "1:1" | "16:9";
  hideImage?: boolean;
};

const ArticleItem: FC<ArticleItemProps> = ({ ...props }) => {
  if (props.type === "small")
    return (
      // TODO: Add "hideImage" prop to all article types
      <ArticleItemSmall
        hideImage={props.hideImage}
        article={props.article}
        ratio={props.ratio}
      />
    );
  if (props.type === "medium")
    return <ArticleItemMedium article={props.article} />;
  return <ArticleItemLarge article={props.article} />;
};

export default ArticleItem;
