import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";
import styles from "./styles.module.css";
import Image from 'next/image';
import { createImageSizes } from '../../utils/image';

type ArticlePushProps = {
  imageSrc: string;
  imageAlt: string;
  text: string;
  href: string;
  bubbleText?: string;
};

const ArticlePush: FC<ArticlePushProps> = (props) => {
  return (
    <Link href={props.href} passHref>
      <a className={classNames([styles.article, "cursor-pointer"])}>
        {props.bubbleText && (
          <div className={classNames(["mb-2"])}>
            <p
              className={classNames([
                "bg-green",
                "p-5",
                "text-white",
                "font-semibold",
              ])}
            >
              {props.bubbleText}
            </p>

            <div
              className={classNames(
                ["w-[30px]", "h-[30px]"],
                "bg-green",
                "ml-12",
                "overflow-hidden"
              )}
            >
              <div
                className={classNames([
                  "w-[50px]",
                  "h-[30px]",
                  "bg-white",
                  "transform",
                  "rotate-45",
                  "-translate-x-1/3",
                  "translate-y-1/3",
                ])}
              />
            </div>
          </div>
        )}

        <div className={classNames("relative", styles["image"])}>
          <Image
            layout='fill'
            sizes={createImageSizes({
              mobile: '100vw',
              tablet: '15vw',
              desktop: '15vw'
            })}
            className="object-cover"
            src={props.imageSrc}
            alt={props.imageAlt}
          />
        </div>
        <div
          className={classNames(["bg-black-lightest", "pt-5", "px-5", "pb-10"])}
        >
          <h3 className={classNames(["text-xl", "font-bold", "leading-snug"])}>
            {props.text}
          </h3>
        </div>
      </a>
    </Link>
  );
};

export default ArticlePush;
