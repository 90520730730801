import classNames from "classnames";
import Image from 'next/image';
import React, { FC } from "react";

type ArticleFooterProps = {
  leagueName: string;
  date: string;

  leaugeIcon: string | null;
};

const ArticleFooter: FC<ArticleFooterProps> = (props) => {
  return (
    <footer className={classNames(["flex", "items-center"])}>
      {props.leaugeIcon ? (
        <Image
          src={props.leaugeIcon}
          alt={props.leagueName}
          width={20}
          height={20}
        />
      ) : (
        <div
          className={classNames(
            "min-w-[20px]",
            "min-h-[20px]",
            "h-[20px]",
            "w-[20px]",
            "bg-black",
            "rounded-full"
          )}
        />
      )}
      <p className={classNames(["ml-2", "text-text-primary", "text-sm"])}>
        {props.leagueName}
        <span className={classNames("text-gray-400", "text-sm")}>
          {" "}
          - {props.date}
        </span>
      </p>
    </footer>
  );
};

export default ArticleFooter;
