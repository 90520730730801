import { useEffect, useRef } from "react";

type VideoPlayerProps = {
  id: string;
};

const VideoPlayer = (props: VideoPlayerProps) => {
  const firstRender = useRef(false);
  const VideoPlayerContainer = useRef<any>(null);

  useEffect(() => {
    if (!firstRender.current) {
      const videoPlayerScript = document.createElement("script");

      videoPlayerScript.src = `https://content.viralize.tv/display/?zid=${props.id}`;
      videoPlayerScript.type = "text/javascript";
      videoPlayerScript.setAttribute("data-wid", "auto");

      VideoPlayerContainer.current.appendChild(videoPlayerScript);

      firstRender.current = true;
    }
  }, [firstRender, props.id]);

  return <div ref={VideoPlayerContainer} />;
};

export default VideoPlayer;
